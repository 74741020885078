/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    Card_mobile_image1: file(relativePath: { eq: "sanitair/sanitair-1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Card_mobile_image2: file(relativePath: { eq: "sanitair/sanitair-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Ribbon_mobile_image: file(relativePath: { eq: "sanitair/sanitair-4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Ribbon_desktop_image: file(
      relativePath: { eq: "sanitair/sanitair-4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Badkamer & toiletruime verbouwen`,
  textWithLink: () => (
    <>
      <p>
        Wilt u uw badkamer, douche of toilet verbouwen? U wilt natuurlijk een
        mooi resultaat met veel comfort, de uitstraling van een mooie badkamer
        staat of valt immers met kwalitatieve materialen en strak tegel- en/of
        stucwerk. E S I - kan u als vakkundige installateur ontzorgen als
        aannemer van de gehele verbouwing van uw badkamer en/of toiletruimte,
        het bouwteam bestaat uit o.a. loodgieter, elektricien, timmerman,
        stukadoor, tegelzetter en kitter.
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0642090304'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
    </>
  ),
};

const textBottom = {
  textWithLink: () => (
    <>
      <AniLink fade to='/sanitair'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Badkamer = ({ data, path, location }) => {
  const seo = {
    title: `Badkamer`,
    description: `Bij ons kunt u terecht voor al uw werkzaamheden aan uw Badkamere voorzieningen.`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.Card_mobile_image1.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Ontwerp, advies en showrooms',
    inner: `De eerste stap bij ons is altijd het bespreken van uw badkamerwensen. Heeft u nog geen idee hoe uw nieuwe badkamer er uit moeten komen te zien? Geen probleem, u kunt op uitnodiging langs bij een tweetal showrooms van onze leveranciers in Purmerend en één in Amsterdam om inspiratie op te doen. Heeft u eenmaal een keuze gemaakt voor het sanitair dat u nodig heeft, dan maken wij een passende en scherpe offerte op maat. Naar aanleiding van uw wensen zorgen wij altijd voor een geschikt ontwerp.`,
  };

  const CardHorizontal2 = {
    noSpace: true,
    textFirst: true,
    img: data.Card_mobile_image2.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Installatie sanitair',
    textWithLink: () => (
      <>
        <p>
          Of het nu gaat om het verbouwen van uw gehele badkamer of slechts om
          de vervanging van uw douchescherm, wij zorgen voor het gehele traject
          van voorbereiding tot en met uitvoering. Wij besteden uiteraard
          aandacht aan een hoog afwerkingsniveau. Na onze installatie kunt u
          rekenen op een geweldig comfort in uw badkamer. Een warme
          douchestraal, een goedwerkende radiator en degelijk sanitair is voor
          onze een hoge prioriteit.
        </p>
        <p>
          Wilt u uw badkamer verbouwen? Neem{' '}
          <AniLink fade to='/contact'>
            contact
          </AniLink>{' '}
          met op met E S I voor een geheel vrijblijvende offerte.
        </p>
      </>
    ),
  };

  const sources = [
    data.Ribbon_mobile_image.childImageSharp.fluid,
    {
      ...data.Ribbon_desktop_image.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `Voor al uw Badkamer`,
    pitchLine: `Laat uw badkamer over aan onze professionals.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        CardHorizontal2={CardHorizontal2}
        textBottom={textBottom}
      />
    </>
  );
};

Badkamer.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Badkamer;
